var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "delivery-target-detail" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "delivery-target-detail-title" },
        [
          _c("div", { staticClass: "table-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.titleArr[_vm.recommendFlowStepType]) +
                "："
            ),
            _c("span", [_vm._v(_vm._s(_vm.totalCount))]),
          ]),
          _c(
            "el-button",
            {
              staticClass: "title-btn export",
              attrs: { loading: _vm.exportLoading, type: "primary" },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出明细")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "top" }, [
        _c("p", { staticClass: "top-header" }, [_vm._v("基本信息")]),
        _c("div", { staticClass: "top-main" }, [
          _c("div", { staticClass: "top-main-left" }, [
            _c("p", { staticClass: "top-main-item" }, [
              _c("span", [_vm._v("时间：")]),
              _c("i", [_vm._v(_vm._s(_vm.baseData.time))]),
            ]),
            _c("p", { staticClass: "top-main-item" }, [
              _c("span", [_vm._v("PM：")]),
              _c("i", { attrs: { title: _vm.baseData.pm } }, [
                _vm._v(_vm._s(_vm.baseData.pm)),
              ]),
            ]),
            _vm.baseData.mc
              ? _c("p", { staticClass: "top-main-item" }, [
                  _c("span", [_vm._v("UL：")]),
                  _c("i", { attrs: { title: _vm.baseData.mc.join(",") } }, [
                    _vm._v(_vm._s(_vm.baseData.mc.join(","))),
                  ]),
                ])
              : _c("p", { staticClass: "top-main-item" }, [
                  _c("span", [_vm._v("MC：")]),
                  _c("i"),
                ]),
            _vm.baseData.firmTeamNames
              ? _c("p", { staticClass: "top-main-item" }, [
                  _c("span", [_vm._v("所属团队：")]),
                  _c(
                    "i",
                    { attrs: { title: _vm.baseData.firmTeamNames.join(",") } },
                    [_vm._v(_vm._s(_vm.baseData.firmTeamNames.join(",")))]
                  ),
                ])
              : _c("p", { staticClass: "top-main-item" }, [
                  _c("span", [_vm._v("所属团队：")]),
                  _c("i"),
                ]),
          ]),
          _c("div", { staticClass: "top-main-right" }, [
            _c("p", { staticClass: "top-main-item" }, [
              _c("span", [_vm._v("客户：")]),
              _c("i", { attrs: { title: _vm.baseData.customerName } }, [
                _vm._v(_vm._s(_vm.baseData.customerName)),
              ]),
            ]),
            _vm.baseData.locations
              ? _c("p", { staticClass: "top-main-item" }, [
                  _c("span", [_vm._v("地点：")]),
                  _c(
                    "i",
                    { attrs: { title: _vm.baseData.locations.join(",") } },
                    [_vm._v(_vm._s(_vm.baseData.locations.join(",")))]
                  ),
                ])
              : _c("p", { staticClass: "top-main-item" }, [
                  _c("span", [_vm._v("地点：")]),
                  _c("i"),
                ]),
            _c("p", { staticClass: "top-main-item" }, [
              _c("span", [_vm._v("事业群/事业部：")]),
              _c(
                "i",
                { attrs: { title: _vm.baseData.customerDeparmentChain } },
                [_vm._v(_vm._s(_vm.baseData.customerDeparmentChain))]
              ),
            ]),
            _c("p", { staticClass: "top-main-item" }),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "main-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
            },
            [
              _vm.recommendFlowStepType == 3 && _vm.isCaculateOpen
                ? [
                    _vm._l(
                      _vm.tableTitles.slice(0, 12),
                      function (item, index) {
                        return _c("el-table-column", {
                          key: "3_" + index,
                          attrs: {
                            prop: item.prop,
                            align: "left",
                            width: item.width,
                            "show-overflow-tooltip": true,
                            label: item.label,
                          },
                        })
                      }
                    ),
                    _vm._l(_vm.extraTableTitle, function (sitem) {
                      return _c(
                        "el-table-column",
                        {
                          key: sitem.field + Math.random(),
                          attrs: {
                            "min-width": sitem.width,
                            align: "left",
                            label: sitem.label,
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm._l(
                                      row.offerItems,
                                      function (offerItem, index) {
                                        return [
                                          offerItem.name == sitem.field
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    key: offerItem.name + index,
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          offerItem.userName +
                                                            "/" +
                                                            _vm.numPointFilter(
                                                              offerItem.percent
                                                            ) +
                                                            "/" +
                                                            offerItem.charge
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                    _c("br"),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          sitem.field == "a2AOfferItems"
                            ? [
                                _vm.row.a2AOfferItems &&
                                _vm.row.a2AOfferItems.length > 0
                                  ? [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            width: "200",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _vm.row.a2AOfferItems.length > 1
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-chat-dot-round",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              })
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.row.a2AOfferItems,
                                            function (a2aItem, aIndex) {
                                              return _c(
                                                "div",
                                                { key: "a2a_" + aIndex },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        a2aItem?.userName
                                                      ) +
                                                      "/" +
                                                      _vm._s(a2aItem?.charge) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.row?.a2AOfferItems[0]?.userName
                                          ) +
                                          "/" +
                                          _vm._s(
                                            _vm.row?.a2AOfferItems[0]?.charge
                                          ) +
                                          "\n                                \n                            "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _vm._l(
                      _vm.tableTitles.slice(15, 17),
                      function (item, index) {
                        return _c("el-table-column", {
                          key: "3_1_" + index,
                          attrs: {
                            prop: item.prop,
                            align: "left",
                            width: item.width,
                            "show-overflow-tooltip": true,
                            label: item.label,
                          },
                        })
                      }
                    ),
                  ]
                : [
                    _vm._l(_vm.tableTitles, function (title, index) {
                      return [
                        title.prop == "jobName" ||
                        (_vm.recommendFlowStepType == 3 &&
                          title.prop == "title")
                          ? _c("el-table-column", {
                              key: index,
                              attrs: {
                                prop: title.prop,
                                align: "left",
                                width: title.width,
                                "show-overflow-tooltip": true,
                                label: title.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: `/#/Job/${row.jobId}`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                title.prop == "jobName"
                                                  ? row.jobName
                                                  : row.title
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : title.prop == "candidateName" ||
                            (_vm.recommendFlowStepType == 3 &&
                              title.prop == "realName")
                          ? _c("el-table-column", {
                              key: index,
                              attrs: {
                                prop: title.prop,
                                align: "left",
                                width: title.width,
                                "show-overflow-tooltip": true,
                                label: title.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: `/#/candidateDetail/${row.candidateId}`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                title.prop == "candidateName"
                                                  ? row.candidateName
                                                  : row.realName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : title.prop == "acceptUser"
                          ? _c("el-table-column", {
                              key: index,
                              attrs: {
                                prop: title.prop,
                                align: "left",
                                width: title.width,
                                "show-overflow-tooltip": true,
                                label: title.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(row.acceptUser || "-")),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : title.prop == "customerDomain"
                          ? _c("el-table-column", {
                              key: index,
                              attrs: {
                                prop: title.prop,
                                align: "left",
                                width: title.width,
                                "show-overflow-tooltip": true,
                                label: title.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(row.customerDomain || "-")
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _c("el-table-column", {
                              key: index,
                              attrs: {
                                prop: title.prop,
                                align: "left",
                                width: title.width,
                                "show-overflow-tooltip": true,
                                label: title.label,
                              },
                            }),
                      ]
                    }),
                  ],
            ],
            2
          ),
          _vm.tableData.length > 0
            ? _c(
                "div",
                { staticClass: "work-table-footer" },
                [
                  _c(
                    "el-pagination",
                    {
                      staticClass: "el-pagination-workTable",
                      attrs: {
                        "current-page": _vm.page,
                        "page-sizes": [20, 30, 50],
                        "page-size": _vm.pageSize,
                        layout: "total, sizes, prev, pager, next, slot",
                        total: _vm.totalCount,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    },
                    [
                      _c("span", { staticClass: "pagination-text" }, [
                        _c(
                          "span",
                          [
                            _vm._v("前往"),
                            _c("el-input", {
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.handlePagerJump.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagerJump,
                                callback: function ($$v) {
                                  _vm.pagerJump = $$v
                                },
                                expression: "pagerJump",
                              },
                            }),
                            _vm._v("页"),
                          ],
                          1
                        ),
                        _c("span", { on: { click: _vm.handlePagerJump } }, [
                          _vm._v("跳转"),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }