<template>
    <div class="delivery-target-detail">
        <div class="content">
            <div class="delivery-target-detail-title">
                <div class="table-title">
                    {{ titleArr[recommendFlowStepType] }}：<span>{{
                        totalCount
                    }}</span>
                </div>
                <el-button
                    class="title-btn export"
                    :loading="exportLoading"
                    type="primary"
                    @click="handleExport"
                    >导出明细</el-button
                >
            </div>
            <div class="top">
                <p class="top-header">基本信息</p>
                <div class="top-main">
                    <div class="top-main-left">
                        <p class="top-main-item">
                            <span>时间：</span><i>{{ baseData.time }}</i>
                        </p>
                        <p class="top-main-item">
                            <span>PM：</span
                            ><i :title="baseData.pm">{{ baseData.pm }}</i>
                        </p>
                        <p v-if="baseData.mc" class="top-main-item">
                            <!-- <span>MC：</span -->
                            <span>UL：</span
                            ><i :title="baseData.mc.join(',')">{{
                                baseData.mc.join(",")
                            }}</i>
                        </p>
                        <p v-else class="top-main-item">
                            <span>MC：</span><i></i>
                        </p>
                        <p v-if="baseData.firmTeamNames" class="top-main-item">
                            <span>所属团队：</span
                            ><i :title="baseData.firmTeamNames.join(',')">{{
                                baseData.firmTeamNames.join(",")
                            }}</i>
                        </p>
                        <p v-else class="top-main-item">
                            <span>所属团队：</span><i></i>
                        </p>
                    </div>
                    <div class="top-main-right">
                        <p class="top-main-item">
                            <span>客户：</span
                            ><i :title="baseData.customerName">{{
                                baseData.customerName
                            }}</i>
                        </p>
                        <p v-if="baseData.locations" class="top-main-item">
                            <span>地点：</span
                            ><i :title="baseData.locations.join(',')">{{
                                baseData.locations.join(",")
                            }}</i>
                        </p>
                        <p v-else class="top-main-item">
                            <span>地点：</span><i></i>
                        </p>
                        <p class="top-main-item">
                            <span>事业群/事业部：</span
                            ><i :title="baseData.customerDeparmentChain">{{
                                baseData.customerDeparmentChain
                            }}</i>
                        </p>
                        <!-- <p class="top-main-item"><span>职位类别：</span><i :title="baseData.jobSerieName">{{baseData.jobSerieName}}</i></p> -->
                        <p class="top-main-item"></p>
                    </div>
                </div>
            </div>
            <div class="main-table">
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    style="width: 100%"
                    tooltip-effect="light"
                >
                    
                    <!-- 处理动态的表头和分成 -->
                    <template v-if="recommendFlowStepType == 3 && isCaculateOpen">
                        <el-table-column
                            v-for="(item,index) in tableTitles.slice(0, 12)"
                            :key="'3_'+index"
                            :prop="item.prop"
                            align="left"
                            :width="item.width"
                            :show-overflow-tooltip="true"
                            :label="item.label"
                        >
                        </el-table-column>
                        <!-- :key="'dynamic_' + sindex" -->
                        <!-- <el-table-column
                            v-for="(sitem, sindex) in 4"
                            :key="Math.random()"
                            width="180"
                            align="left"
                            :show-overflow-tooltip="true"
                            >
                            <template slot="header">
                                {{dynamicTitle[sindex] + '/分成比例/分成值'}}
                            </template>
                            
                            <template slot-scope="{ row }">
                                {{row.offerItems[sindex]?.userName+'/'+numPointFilter(row.offerItems[sindex]?.percent)+'/'+row.offerItems[sindex]?.charge}}
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            v-for="sitem in extraTableTitle"
                            :key="sitem.field + Math.random()"
                            :min-width="sitem.width"
                            align="left"
                            :label="sitem.label"
                            :show-overflow-tooltip="true"
                            >
                            <template slot-scope="{ row }">
                                <template v-for="(offerItem, index) in row.offerItems">
                                    <template v-if="offerItem.name == sitem.field">
                                        <span :key="offerItem.name + index">
                                            {{offerItem.userName + '/' + numPointFilter(offerItem.percent) + '/' + offerItem.charge}}
                                            <br>
                                        </span>
                                    </template>
                                </template>
                            </template>
                            <template v-if="sitem.field == 'a2AOfferItems'">
                                <template v-if="row.a2AOfferItems && row.a2AOfferItems.length >0">
                                    
                                    <el-popover
                                        placement="top-start"
                                        width="200"
                                        trigger="hover"
                                        >
                                        <i slot="reference" 
                                            v-if="row.a2AOfferItems.length>1"
                                            class="el-icon-chat-dot-round"></i>
                                        <div 
                                            v-for="(a2aItem, aIndex) in row.a2AOfferItems"
                                            :key="'a2a_' + aIndex"
                                            >
                                            {{a2aItem?.userName}}/{{a2aItem?.charge}}
                                        </div>
                                    </el-popover>
                                    {{row?.a2AOfferItems[0]?.userName}}/{{row?.a2AOfferItems[0]?.charge}}
                                    
                                </template>
                            </template>
                        </el-table-column>

                        <!-- v-for="(item,index) in tableTitles.slice(14, 16)" -->
                        <el-table-column
                            v-for="(item,index) in tableTitles.slice(15, 17)"
                            :key="'3_1_'+index"
                            :prop="item.prop"
                            align="left"
                            :width="item.width"
                            :show-overflow-tooltip="true"
                            :label="item.label"
                        >
                        </el-table-column>

                    </template>
                    
                    <template v-else>
                    <template v-for="(title, index) in tableTitles">
                        <el-table-column
                            v-if="
                                title.prop == 'jobName' ||
                                (recommendFlowStepType == 3 &&
                                    title.prop == 'title')
                            "
                            :key="index"
                            :prop="title.prop"
                            align="left"
                            :width="title.width"
                            :show-overflow-tooltip="true"
                            :label="title.label"
                        >
                            <template slot-scope="{ row }">
                                <!-- <a
                                    target="_blank"
                                    :href="`/Headhunting/MyCompany.html#/Job/${row.jobId}`"
                                    >{{
                                        title.prop == "jobName"
                                            ? row.jobName
                                            : row.title
                                    }}</a
                                > -->
                                <a
                                    target="_blank"
                                    :href="`/#/Job/${row.jobId}`"
                                    >{{
                                        title.prop == "jobName"
                                            ? row.jobName
                                            : row.title
                                    }}</a
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-else-if="
                                title.prop == 'candidateName' ||
                                (recommendFlowStepType == 3 &&
                                    title.prop == 'realName')
                            "
                            :key="index"
                            :prop="title.prop"
                            align="left"
                            :width="title.width"
                            :show-overflow-tooltip="true"
                            :label="title.label"
                        >
                            <template slot-scope="{ row }">
                                <!-- <a
                                    target="_blank"
                                    :href="`/Headhunting/MyCompany.html#/candidateDetail/${row.candidateId}`"
                                    >{{
                                        title.prop == "candidateName"
                                            ? row.candidateName
                                            : row.realName
                                    }}</a
                                > -->
                                <a
                                    target="_blank"
                                    :href="`/#/candidateDetail/${row.candidateId}`"
                                    >{{
                                        title.prop == "candidateName"
                                            ? row.candidateName
                                            : row.realName
                                    }}</a
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-else-if="title.prop == 'acceptUser'"
                            :key="index"
                            :prop="title.prop"
                            align="left"
                            :width="title.width"
                            :show-overflow-tooltip="true"
                            :label="title.label"
                        >
                            <template slot-scope="{ row }">
                                <span>{{ row.acceptUser || "-" }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-else-if="title.prop == 'customerDomain'"
                            :key="index"
                            :prop="title.prop"
                            align="left"
                            :width="title.width"
                            :show-overflow-tooltip="true"
                            :label="title.label"
                        >
                            <template slot-scope="{ row }">
                                <span>{{ row.customerDomain || "-" }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-else
                            :key="index"
                            :prop="title.prop"
                            align="left"
                            :width="title.width"
                            :show-overflow-tooltip="true"
                            :label="title.label"
                        >
                        </el-table-column>
                    </template>
                    </template>
                </el-table>
                <div class="work-table-footer" v-if="tableData.length > 0">
                    <el-pagination
                        :current-page="page"
                        :page-sizes="[20, 30, 50]"
                        :page-size="pageSize"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        class="el-pagination-workTable"
                        layout="total, sizes, prev, pager, next, slot"
                        :total="totalCount"
                    >
                        <span class="pagination-text">
                            <span
                                >前往<el-input
                                    v-model="pagerJump"
                                    @keyup.enter.native="handlePagerJump"
                                ></el-input
                                >页</span
                            >
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { deliveryTarget as deliveryTargetUrl } from "#/js/config/javaApi.json";
import { currency as currencyFilter } from "#/js/filters/number.js";
import moment from "moment";
import myService from "#/js/service/myService.js";
import { downloadFile } from "#/js/util/fileUtil.js";
export default {
    name: "DeliveryTarget",
    components: {},
    data() {
        return {
            loading: false,
            exportLoading: false,
            totalCount: 0,
            page: 1,
            pageSize: 10,
            pagerJump: 0,
            baseData: {},
            tableData: [],
            recommendFlowStepType: 1,
            extraTableTitle: [],
            titleArr: ["", "人才推荐数", "面试数", "Offer数"],
            recommendTitles: [
                { label: "推荐顾问", prop: "realName", width: 142 },
                { label: "推荐公司", prop: "customerName", width: 142 },
                { label: "所属领域", prop: "customerDomain", width: 142 },
                { label: "推荐职位", prop: "jobName", width: 132 },
                { label: "职位来源", prop: "jobSource", width: 150 },
                { label: "推荐时间", prop: "recommendTime", width: 140 },
                { label: "最近处理时间", prop: "operationTime", width: 140 },
                { label: "候选人姓名", prop: "candidateName", width: 150 },
                { label: "职位", prop: "title", width: 142 },
                { label: "公司", prop: "company", width: 128 },
                { label: "当前推荐状态", prop: "status", width: 150 },
                { label: "接受推荐者", prop: "acceptUser", width: 142 },
                { label: "简历来源渠道", prop: "candidateSourceChannel", width: 180 },
            ],
            interviewTitles: [
                { label: "推荐顾问", prop: "realName", width: 142 },
                { label: "推荐时间", prop: "recommendTime", width: 124 },
                {
                    label: "TS确认面试时间",
                    prop: "recommenderCFMedTime",
                    width: 140,
                },
                {
                    label: "PM确认面试时间",
                    prop: "jobOwnerCFMedTime",
                    width: 140,
                },
                { label: "候选人状态", prop: "status", width: 120 },
                { label: "候选人姓名", prop: "candidateName", width: 140 },
                { label: "职位", prop: "title", width: 104 },
                { label: "公司", prop: "company", width: 124 },
                { label: "推荐公司", prop: "customerName", width: 114 },
                { label: "所属领域", prop: "customerDomain", width: 114 },
                { label: "推荐职位", prop: "jobName", width: 132 },
                { label: "职位来源", prop: "jobSource", width: 150 },
                { label: "接受推荐者", prop: "acceptUser", width: 142 },
                { label: "简历来源渠道", prop: "candidateSourceChannel", width: 180 },
            ],
            offerTitles: [
                { label: "推荐人", prop: "recommendName", width: 100 },
                { label: "客户名称", prop: "customerName", width: 104 },
                { label: "所属领域", prop: "customerDomain", width: 142 },
                { label: "事业部", prop: "department", width: 130 },
                { label: "推荐至职位", prop: "title", width: 120 },
                { label: "职位来源", prop: "jobSource", width: 150 },
                { label: "候选人", prop: "realName", width: 90 },
                { label: "目前公司", prop: "currentCompany", width: 102 },
                { label: "offer时间", prop: "offerTime", width: 180 },
                { label: "入职时间", prop: "hireTime", width: 180 },
                { label: "服务费", prop: "serviceCharge", width: 110 },
                { label: "业绩", prop: "performance", width: 110 },
                { label: "BD-客户bd/分成比例/分成值", prop: "BD", width: 180 },
                {
                    label: "PM-职位发布/分成比例/分成值",
                    prop: "PM",
                    width: 180,
                },
                { label: "归属/分成比例/分成值", prop: "CS", width: 180 },
                // { label: "推荐者/分成比例/分成值", prop: "TS", width: 200 },
                // { label: '非正常的/分成比例/分成值', prop: 'jobName', width: 132 },
                { label: "候选人状态", prop: "candidateStatus", width: 132 },
                { label: "简历来源渠道", prop: "candidateSourceChannel", width: 180 },
            ],
            simpleOfferTitles: [
                { label: "推荐人", prop: "recommendName", width: 170 },
                { label: "客户名称", prop: "customerName", width: 174 },
                { label: "所属领域", prop: "customerDomain", width: 142 },
                { label: "事业部", prop: "department", width: 170 },
                { label: "推荐至职位", prop: "title", width: 182 },
                { label: "候选人", prop: "realName", width: 160 },
                { label: "目前公司", prop: "currentCompany", width: 172 },
                { label: "offer时间", prop: "offerTime", width: 180 },
                { label: "入职时间", prop: "hireTime", width: 180 },
                // { label: '非正常的/分成比例/分成值', prop: 'jobName', width: 132 },
                { label: "候选人状态", prop: "candidateStatus", width: 180 },
            ],
            dynamicTitle: [ 'PM', 'BD', 'TS', 'CS' ],
        };
    },
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser || false;
        },
        tableTitles() {
            let _tableTitles = '';
            if (this.recommendFlowStepType == 1) {
                _tableTitles = this.recommendTitles;
            } else if (this.recommendFlowStepType == 2) {
                _tableTitles = this.interviewTitles;
            } else if (this.recommendFlowStepType == 3) {
                if (this.isCaculateOpen) {
                    _tableTitles = this.offerTitles;
                } else {
                    _tableTitles = this.simpleOfferTitles;
                }
            }
            
            // 非cf不显示所属领域
            if(this.isCFUser) {
                return _tableTitles;
            } else {
                return _tableTitles.filter(item => item.prop != 'customerDomain');
            }
        },
        isCaculateOpen() {
            return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
        },
    },
    filters: {},
    created() {},
    mounted() {
        // if(!this.$store.state.user.userInfo.isAdministrator && !this.$store.state.user.userInfo.isAdminOrTeamLeader) {
        //     shortTips("权限不足，请联系管理员");
        //     setTimeout(() => {
        //         location.href = "/Headhunting/#/";
        //     }, 2000);
        // } else {
            if (sessionStorage.getItem("deliveryTargetDetailData")) {
            } else if (localStorage.getItem("deliveryTargetDetailData")) {
                let jsonStr = localStorage.getItem("deliveryTargetDetailData");
                sessionStorage.setItem("deliveryTargetDetailData", jsonStr);
            }
            let json = JSON.parse(
                sessionStorage.getItem("deliveryTargetDetailData")
            );
            this.recommendFlowStepType = json.recommendFlowStepType;

            // this.startDate = json.startDate;
            // this.endDate = json.endDate;
            // this.projectId = json.projectId;
            // this.recommendFlowStepType = json.recommendFlowStepType;
            // this.statisticsTimeType = json.statisticsTimeType;
            Object.assign(this, json);
            this.getData();
        // }
    },
    methods: {
        numPointFilter(val) {
            return val?.replace(".00", "");
        },
        dataFilter(val) {
            return moment(val).format("YYYY-MM-DD HH:mm:SS");
        },
        computedTableTitle(row) {
            return row.offerItems[sindex]?.userName + '-职位发布/分成比例/分成值'
        },
        // 判断是否显示A2a分成列
        hasA2aColumn(arr = []) {
            if(arr.length == 0) return false;
            return arr.some(item => {
                return item.a2AOfferItems && item.a2AOfferItems?.length > 0;
            });
        },
        getData() {
            this.loading = true;
            let data = {
                startDate: this.startDate,
                endDate: this.endDate,
                offset: (this.page - 1) * this.pageSize,
                pageSize: this.pageSize,
                projectId: this.projectId,
                userId: this.userId,
                jobId: this.jobId,
                recommendFlowStepType: this.recommendFlowStepType,
                statisticsTimeType: this.statisticsTimeType,
            };
            console.log(data)
            _request({
                method: "POST",
                url: deliveryTargetUrl.customer_data_detail,
                baseURL: "LbdJavaApi",
                javaProject: "performance",
                data: data,
            }).then((res) => {
                this.totalCount = res.count || 0;

                if(res.performanceNames && res.performanceNames.length > 0) {
                    this.extraTableTitle = res.performanceNames.map(item =>{
                        return {
                            field: item,
                            label: `${item}/分成比例/分成值`,
                            class: '',
                            width: '188'
                        }
                    });
                    console.log(this.extraTableTitle)
                }

                if(res.data && this.hasA2aColumn(res.data)) {
                    const _idx = this.extraTableTitle.findIndex(item => item.field == 'a2AOfferItems');
                    if(_idx < 0) {
                        this.extraTableTitle.push({
                            field: 'a2AOfferItems',
                            label: `A2A/分成值`,
                            class: '',
                            width: '188'
                        });
                    }
                }

                if (this.recommendFlowStepType == 3) {
                    let list = res.data || [];
                    list.forEach((item) => {
                        if ( item && item.offerItems && item.offerItems.length > 0 ) {
                            item.offerItems.forEach((offerItem) => {
                                if (item[offerItem.name]) {
                                    item[offerItem.name] = `${ item[offerItem.name] }、${ offerItem?.userName }/${this.numPointFilter( offerItem?.percent )}/${this.numPointFilter( offerItem?.charge )}`;
                                } else {
                                    item[offerItem.name] = `${ offerItem?.userName }/${this.numPointFilter( offerItem?.percent )}/${this.numPointFilter( offerItem?.charge )}`;
                                }
                            });
                        }
                        item.hireTime = this.dataFilter(item.hireTime);
                        item.offerTime = this.dataFilter(item.offerTime);
                    });
                    this.tableData = list;

                    if(list[0]?.offerItems && list[0]?.offerItems.length > 0) {
                        this.$nextTick(() => {
                            this.dynamicTitle = list[0].offerItems.map(item => item.name);
                        })
                    }
                    
                } else {
                    this.tableData = res.data || [];
                }

                this.baseData = res.extraData || {};
            }).finally(() =>{
                this.loading = false;
            });
        },
        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount / this.pageSize);
            if (currentPager > 0 && currentPager <= currentPageTotal) {
                this.page = currentPager;
                this.getData();
            }
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.page = 1;
            this.pageSize = val;
            this.getData();
        },
        handleExport() {
            this.exportLoading = true;
            let data = {
                startDate: this.startDate,
                endDate: this.endDate,
                offset: (this.page - 1) * this.pageSize,
                pageSize: this.pageSize,
                projectId: this.projectId,
                userId: this.userId,
                recommendFlowStepType: this.recommendFlowStepType,
                statisticsTimeType: this.statisticsTimeType,
                jobId: this.jobId,
            };
            _request({
                method: "POST",
                url: deliveryTargetUrl.customer_data_detail_export,
                baseURL: "LbdJavaApi",
                javaProject: "performance",
                responseType: "blob",
                data: data,
            })
                .then((res) => {
                    downloadFile(res);
                })
                .finally(() => {
                    this.exportLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.delivery-target-detail {
    // background-color: #f9f9f9;
    overflow-x: auto;
    padding: 20px;
    height: 100%;
    overflow-y: auto;

    .content {
        // width: 1500px;
        // margin: 40px auto 0;
        // padding: 0 20px;
        // overflow: hidden;
        background-color: #fff;
        border-radius: 8px;
        min-height: calc(100vh - 90px);

        .delivery-target-detail-title {
            display: flex;
            justify-content: space-between;
            line-height: 32px;
            padding: 30px 30px 0;
            margin-bottom: 8px;

            .table-title {
                font-size: 28px;
                color: #666;
                line-height: 32px;
                > span {
                    color: $primary;
                }
            }

            // .title-btn {
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     margin-top: 32px;
            // }
            .export{
                margin-left: 20px;
            }
        }

        .top {
            margin: 30px 20px 0;
            // width: 100%;
            height: 210px;
            background-color: #f8fbfa;

            &-header {
                position: relative;
                height: 56px;
                line-height: 56px;
                padding-left: 20px;
                margin: 0;
                border-bottom: 1px solid #eee;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -10px;
                    width: 8px;
                    height: 20px;
                    background-color: $primary;
                }
            }

            &-main {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #333;

                &-left {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex-shrink: 0;
                    width: 492px;
                    padding: 0 30px;
                }
                &-right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    flex-grow: 1;

                    .top-main-item {
                        padding-right: 20px;
                        height: 38px;
                        span {
                            width: 118px;
                        }
                    }
                }
                &-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    line-height: 38px;
                    margin-bottom: 0;

                    span {
                        flex-shrink: 0;
                        display: inline-block;
                        width: 84px;
                        color: #999;
                    }

                    i {
                        display: inline-block;
                        max-width: 560px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }

        .main-table {
            margin-top: 30px;

            /deep/ .el-table {
                th, td {
                    padding: 8px 0;
                }
                thead.has-gutter tr th {
                    background-color: #F0F4F7;
                    .cell {
                        color: #666;
                        font-weight: bold;
                        padding: 0 20px;
                    }
                }
                td .cell {
                    padding: 0 20px;
                }
            }
        }
    }

    .work-table-footer {
        // margin: 20px 0;
        margin: 35px 35px 0 0;
        overflow: hidden;
    }
}

</style>
<style lang="scss">
.layout-transition {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>